module.exports = {
  justNow: "just now",
  secondsAgo: "{{time}} seconds ago",
  aMinuteAgo: "a minute ago",
  minutesAgo: "{{time}} minutes ago",
  anHourAgo: "an hour ago",
  hoursAgo: "{{time}} hours ago",
  aDayAgo: "yesterday",
  daysAgo: "{{time}} days ago",
  aWeekAgo: "a week ago",
  weeksAgo: "{{time}} weeks ago",
  aMonthAgo: "a month ago",
  monthsAgo: "{{time}} months ago",
  aYearAgo: "a year ago",
  yearsAgo: "{{time}} years ago",
  overAYearAgo: "over a year ago",
  secondsFromNow: "{{time}} seconds from now",
  aMinuteFromNow: "a minute from now",
  minutesFromNow: "{{time}} minutes from now",
  anHourFromNow: "an hour from now",
  hoursFromNow: "{{time}} hours from now",
  aDayFromNow: "tomorrow",
  daysFromNow: "{{time}} days from now",
  aWeekFromNow: "a week from now",
  weeksFromNow: "{{time}} weeks from now",
  aMonthFromNow: "a month from now",
  monthsFromNow: "{{time}} months from now",
  aYearFromNow: "a year from now",
  yearsFromNow: "{{time}} years from now",
  overAYearFromNow: "over a year from now"
}
